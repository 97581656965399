import { d } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from "@emotion/core";

import { LangIcon } from "./svg-icons";
import React from "react";
import colors from "../../common/colors";
import i18n from "../../../../i18n";
import languages from "./languages";
import styled from "@emotion/styled";

const Holder = styled.div`
  position: absolute;
  bottom: 150px;

  left: ${parseInt(d.glanceSideGap) * 2}px;
  right: ${parseInt(d.glanceSideGap) * 2}px;
  z-index: 4;
`;
const LangButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LangSelect = styled.div`
  text-align: center;
  button {
    padding: 10px;
    background-color: ${p =>
      p.type === "invert" ? "transparent" : colors.silver};
    border: 1px solid
      ${p => (p.type === "invert" ? colors.silver : "transparent")};
    outline: 0;
    border-radius: 20px;
    min-width: 61px;
    min-height: 61px;
    width: 100%;
    overflow: hidden;
    position: relative;
    fill: ${p => (p.type === "invert" ? colors.white : colors.glanceRed)};
    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      border-radius: 50%;
      z-index: 0;
    }
    &:after {
      background-color: ${p =>
        p.type === "invert" ? colors.white : colors.glanceRed};
      transform: scale(0);
      transition: 0.5s transform;
      z-index: 0;
    }
  }
  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.21;
    text-align: center;
    color: ${colors.silver};
    display: block;
    margin-top: 10px;
    transition: 0.5s color;
  }
  &.selected {
    button {
      border-color: ${p =>
        p.type === "invert" ? colors.white : "transparent"};
      fill: ${p => (p.type === "invert" ? colors.redRibbon : colors.white)};
      &:after {
        transform: scale(1.5);
        background-color: ${p =>
          p.type === "invert" ? colors.white : colors.redRibbon};
      }
    }
    label {
      color: ${p => (p.type === "invert" ? colors.white : colors.black)};
    }
  }
`;

const Icon = styled(LangIcon)`
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
`;

const LangButton = styled.button``;
const LangLabel = styled.label``;

const LanguageSelect = ({ t, type = "", onLanguageSelect = () => {} }) => {
  const [selectedLanguages, setSelectedLanguages] = React.useState([]);
  const onSelect = React.useCallback(
    lang => {
      return () => {
        let langs = [...selectedLanguages];
        if (langs.includes(lang)) {
          langs.splice(langs.indexOf(lang), 1);
        } else {
          langs.push(lang);
        }

        langs.unshift(languages[0].langName);
        langs = [...new Set(langs)];
        setSelectedLanguages(langs);
        onLanguageSelect(langs);
      };
    },
    [onLanguageSelect, selectedLanguages]
  );

  React.useEffect(() => {
    onSelect(languages[0].langName)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Holder type={type} data-testid="language-select">
      <LangButtonHolder>
        {languages.map(lang => {
          const { langName, ...rest } = lang;
          const name = lang.langName;
          return (
            <LangSelect
              {...rest}
              key={name}
              type={type}
              role="button"
              className={selectedLanguages.includes(name) ? "selected" : null}
              onClick={onSelect(name)}
            >
              <LangButton id={name}>
                <Icon name={name} />
              </LangButton>
              <LangLabel className="text" htmlFor={name}>
                {lang.text}
              </LangLabel>
            </LangSelect>
          );
        })}
      </LangButtonHolder>
    </Holder>
  );
};

export default i18n.withTranslation("common")(LanguageSelect);
