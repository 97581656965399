export default [
  {
    id: "#fashion",
    text: "Fashion",
    type: "portrait",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/fashion.jpg"
  },
  {
    id: "#health_n_fitness",
    text: "Fitness",
    type: "portrait",
    parent: "#fashion",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/fitness.jpg"
  },
  {
    id: "#national",
    text: "News",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/news.jpg"
  },
  {
    id: "#technology",
    text: "Tech",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/tech.jpg"
  },
  {
    id: "#entertainment",
    text: "Entertainment",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/entertainment.jpg"
  },
  {
    id: "#sports",
    text: "Sports",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/sports.jpg"
  },
  {
    id: "#travel",
    text: "Travel",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/travel.jpg"
  },
  {
    id: "#animals",
    text: "Animals",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/wildlife.jpg"
  },
  {
    id: "#business",
    text: "Business News",
    parent: "#international",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/business-news.jpg"
  },
  {
    id: "#fun_facts",
    text: "Fun Facts",
    parent: "#music",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/fun-facts.jpg"
  },
  {
    id: "#automotive",
    text: "Cars Bikes",
    parent: "#technology",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/cars-bikes.jpg"
  },
  {
    id: "#international",
    text: "World News",
    parent: "#national",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/world-news.jpg"
  },
  {
    id: "#games",
    text: "Games",
    parent: "#sports",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/games.jpg"
  },
  {
    id: "#food",
    text: "Food",
    parent: "#travel",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/food.jpg"
  },
  {
    id: "#music",
    text: "Music",
    parent: "#entertainment",
    image:
      "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/categories/music.jpg"
  }
];
