export default [
  {
    langName: "en",
    text: "English",
    image: "/static/images/buzzstorm/lang-en.svg",
    helloText: "Hello"
  },
  {
    langName: "hi",
    text: "हिंदी",
    image: "/static/images/buzzstorm/lang-hi.svg",
    helloText: "हैलो"
  },
  {
    langName: "te",
    text: "తెలుగు",
    image: "/static/images/buzzstorm/lang-te.svg",
    helloText: "హలో"
  },
  {
    langName: "ta",
    text: "தமிழ்",
    image: "/static/images/buzzstorm/lang-ta.svg",
    helloText: "ஹலோ"
  }
];
