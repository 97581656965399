import styled from "@emotion/styled";
import React, { useState, useEffect, useCallback } from "react";
import colors from "../../common/colors";
import { css, keyframes } from "emotion";
import fonts from "../../common/font";
import { Global } from "@emotion/core";
import { fireEvent } from "../../utils/tracking";
import { enableGlance, launchHomeScreen } from "../../utils/glanceJsBridges";
import { storeSessionStorage, getSessionStorage } from "../../utils/browser";
import i18n from "../../../../i18n";
import { LATAM_REGIONS } from "../../common/constants";

import {
  TURN_ON_GLANCE,
  GLANCE_TEXT,
  ACTIVATION_TIME_UP_TEXT,
  TIME_UP_TEXT,
  ACTIVATION_POP_UP_TEXT,
  POP_UP_TEXT,
  BUTTON_TEXT,
  ACTIVATION_BUTTON_TEXT,
  PRIVACY_POLICY,
  EULA
} from "../../common/constants";
const twoMinutes = 120000;

const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:0.85;
  }
`;

const MaskView = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${colors.black};
  animation: ${fadeIn} 2s forwards;
`;

const slideInUp = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 2;
  background-image: linear-gradient(
    to bottom,
    ${colors.darkGray},
    ${colors.darkGray},
    ${colors.darkGray}
  );
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 30px 30px 0px 0px;
  bottom: 0;
  animation: ${slideInUp} 2s;
  @media (min-width: 799px) {
    max-width: 450px;
    max-height: 446px;
  }
`;

const ImageContainer = styled.div`
  ${props => (props.isTimeUp ? `display: none;` : `position: absolute;`)}
  top: 26px;
  right: 26px;
`;

const IconContainer = styled.div`
  background-image: ${props =>
    props.isTimeUp
      ? `url(/static/images/Popup3.gif);`
      : `url(/static/images/Popup1.gif);`} 
  height: 187px;
  background-position: center;
  background-size: cover;
  width: 113px;
  margin: 30px 0 0 10px;
`;

const SuccessIconContainer = styled.div`
  background-image: url(/static/images/SuccessPopup.gif);
  height: 187px;
  background-position: center;
  background-size: cover;
  width: 113px;
`;

const Subtext = styled.div`
  font-size: ${fonts.fontLarge};
  font-weight: ${fonts.fontWtExtraBold};
  line-height: ${fonts.fontDisplay};
  text-align: center;
  color: ${colors.white};
  width: 60%;
  text-align: center;
  margin-top: ${props => (props.fromActivation ? `10%;` : `0%`)};
  margin-bottom: 10%;
`;

const ButtonContainer = styled.div`
  margin-bottom: 40px;
  margin-top: 16px;
`;

const Button = styled.div`
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: ${fonts.fontMedium};
  background-color: ${props =>
    props.fromActivation ? colors.white : colors.glanceRed};
  color: ${props => (props.fromActivation ? colors.glanceRed : colors.white)};
  width: ${props => (props.isLATAMregion ? "auto" : "80%")};
  heght: 40px;
  margin: auto;
  padding: ${props => (props.isLATAMregion ? "10px 50px" : "10px 0px")};
  font-family: Roboto, "Urbanist";
  font-size: ${fonts.fontBase};
`;

const TermsAndCondition = styled.p`
  font-size: ${fonts.fontSmall};
  font-weight: ${fonts.fontWtLight};
  text-align: center;
  color: ${colors.white};
`;

const GlanceTextAfterTime = styled.p`
  font-size: ${fonts.fontMedium};
  font-style: ${fonts.fontWtNormal};
  font-weight: ${fonts.fontWtNormal};
  text-align: center;
  color: ${colors.white};
`;

const LeftArrow = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
`;

const GlobalStyles = css`
  body {
    overflow: hidden;
  }
`;

const PolicyLink = styled.a`
  text-decoration: underline;
  color: ${colors.blue};
`;
const ActivationPopup = props => {
  const {
    glanceLength,
    glanceIndex,
    pauseAutoPlay,
    fromActivation,
    pageName,
    OEM_DETAILS: { region, oemName, locale },
    t: translate
  } = props;

  const [isActivationCampaignVisible, toggleActivationCampaign] = useState(
    false
  );

  const [activationPopupClicked, updateActivationPopupClicked] = useState(
    false
  );

  const [ctaActivationBuffer, updateCtaActivationBuffer] = useState(0);

  const isLATAMregion = LATAM_REGIONS.indexOf(region) > -1;

  const [timerEnd, updateTimerEnd] = useState(false);
  const [buttonClicked, updateButtonClicked] = useState(false);

  const activationPopupText = fromActivation
    ? translate(GLANCE_TEXT)
    : TURN_ON_GLANCE + GLANCE_TEXT.toLocaleLowerCase();

  const timeUpText = fromActivation
    ? translate(ACTIVATION_TIME_UP_TEXT)
    : TIME_UP_TEXT;

  const timeUpGlanceText = fromActivation
    ? translate(ACTIVATION_POP_UP_TEXT)
    : POP_UP_TEXT;

  const buttonText = fromActivation
    ? translate(ACTIVATION_BUTTON_TEXT)
    : BUTTON_TEXT;

  let timer = null;

  const privacyPolicyLink = `${PRIVACY_POLICY}?region=${region}&utm_source=${oemName}&locale=${locale}`;
  const eulaLink = `${EULA}?region=${region}&utm_source=${oemName}&locale=${locale}`;

  useEffect(() => {
    startTwoMinutesTimer();
    updateActivationPopupClicked(getSessionStorage("activationPopupClicked"));
    updateCtaActivationBuffer(
      parseInt(getSessionStorage("ctaActivationBuffer") || 0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTwoMinutesTimer = useCallback(() => {
    if (timer != null) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      pauseAutoPlay(true);
      updateTimerEnd(true);
      toggleActivationCampaign(true);
    }, twoMinutes);
  });

  useEffect(() => {
    if (glanceLength > 1) {
      //this condition is to show the activation popup for 2nd,6th,10th,14th... glances
      if (
        !activationPopupClicked &&
        glanceIndex > 0 &&
        ((ctaActivationBuffer === 0 && (glanceIndex + 3) % 4 === 0) ||
          (ctaActivationBuffer > 0 &&
            (glanceIndex + ctaActivationBuffer) % 4 === 1))
      ) {
        pauseAutoPlay(true);
        toggleActivationCampaign(true);
      } else if (
        //this condition is to avoid showing popup for 2nd glance after redirection happens, in this case glanceIndex is reset to 0
        activationPopupClicked &&
        glanceIndex > 0 &&
        (glanceIndex + ctaActivationBuffer) % 4 === 0
      ) {
        pauseAutoPlay(true);
        toggleActivationCampaign(true);
      }
    } else if (glanceIndex === 0) {
      //this condition is added so that the popup will show up at first glance when glances are less than 2.
      pauseAutoPlay(true);
      toggleActivationCampaign(true);
    }
  }, [
    glanceIndex,
    glanceLength,
    pauseAutoPlay,
    activationPopupClicked,
    ctaActivationBuffer
  ]);

  const backHandler = useCallback(() => {
    toggleActivationCampaign(false);
    updateButtonClicked(false);
    pauseAutoPlay(false);
  }, [pauseAutoPlay]);

  const onClickEnableGlance = useCallback(() => {
    backHandler();
    updateButtonClicked(true);
    switch (pageName) {
      case "discovery":
        fireEvent(
          `${oemName} - EnabledGlance - App`,
          `Reactivation-${oemName}`,
          "Reactivation"
        );
        window.location = "glance://reactivation/enable";
        break;
      case "xiaomi_discovery":
        fireEvent(
          "Xiaomi - EnabledGlance - App",
          "Reactivation-xiaomi",
          "Reactivation"
        );
        enableGlance("xiaomi_discovery");
        break;
      default:
        fireEvent(
          "Xiaomi - EnabledGlance - App",
          "Reactivation",
          "Reactivation"
        );
        window.location = "fashiongallery://home";
    }
    updateTimerEnd(false);
    startTwoMinutesTimer();
  }, [oemName, backHandler, pageName, startTwoMinutesTimer]);

  useEffect(() => {
    let successTimer;
    if (buttonClicked) {
      successTimer = setTimeout(() => {
        updateButtonClicked(false);
      }, 3000);
    }
    return () => {
      clearTimeout(successTimer);
    };
  }, [buttonClicked]);

  const onEulaPPClick = useCallback(() => {
    storeSessionStorage("activationPopupClicked", true);
    storeSessionStorage("ctaActivationBuffer", 0);
  }, []);

  const onClickBackButton = useCallback(() => {
    backHandler();
    updateTimerEnd(false);
    switch (pageName) {
      case "discovery":
        window.location = "glance://reactivation/finish";
        break;
      case "xiaomi_discovery":
        launchHomeScreen("xiaomi_discovery");
        break;
      default:
        break;
    }
  }, [backHandler, pageName]);

  if (!isActivationCampaignVisible && !buttonClicked) {
    return null;
  }
  return (
    <>
      {buttonClicked && (
        <Container data-testid="activation-success">
          <SuccessIconContainer></SuccessIconContainer>
          <Subtext> {translate("Experience Glance Now")}! </Subtext>
        </Container>
      )}
      {isActivationCampaignVisible && <Global styles={GlobalStyles} />}
      {isActivationCampaignVisible && (
        <MaskView isTimeUp={timerEnd}>
          {fromActivation && timerEnd && (
            <LeftArrow onClick={onClickBackButton} />
          )}
        </MaskView>
      )}
      {isActivationCampaignVisible && (
        <>
          <Container isTimeUp={timerEnd}>
            <ImageContainer isTimeUp={timerEnd}>
              <img
                onClick={backHandler}
                src={"/static/images/close.svg"}
                height={16}
                width={16}
              />
            </ImageContainer>
            {!fromActivation && (
              <IconContainer isTimeUp={timerEnd}></IconContainer>
            )}
            <Subtext fromActivation={fromActivation}>
              {timerEnd ? timeUpText : activationPopupText}
            </Subtext>
            {timerEnd && (
              <GlanceTextAfterTime> {timeUpGlanceText} </GlanceTextAfterTime>
            )}
            <ButtonContainer>
              <Button
                isLATAMregion={isLATAMregion}
                data-testid="turn-on-glance"
                fromActivation={fromActivation}
                onClick={onClickEnableGlance}
              >
                {buttonText}
              </Button>
              {!isLATAMregion && (
                <TermsAndCondition>
                  {translate("By turning this feature on, you’re agreeing to")}
                  &nbsp;&nbsp;
                  <PolicyLink onClick={onEulaPPClick} href={eulaLink}>
                    {translate("Glance End User Licence Agreement")}
                  </PolicyLink>
                  &nbsp;{translate("And")}&nbsp;&nbsp;
                  <PolicyLink onClick={onEulaPPClick} href={privacyPolicyLink}>
                    {translate("Privacy Policy")}
                  </PolicyLink>
                </TermsAndCondition>
              )}
            </ButtonContainer>
          </Container>
        </>
      )}
    </>
  );
};

export default i18n.withTranslation("common")(ActivationPopup);
