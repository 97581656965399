export const SCREEN_SWITCH_DURATION = 1500;
export const HIGHLIGHT_AUTOPLAY_TIME = 7000;
export const SCREENS = {
  // sbould be number type ascending order
  SPLASH: 0,
  GET_STARTED: 1,
  LANGUAGE_SELECTION: 2,
  CATEGORY_SELECTION: 3,
  HIGHLIGHT: 4
};

export const d = {
  sideGap: "40px",
  glanceSideGap: "20px",
  // glanceTopGap: "46px"
  glanceTopGap: "30px",
  glanceBottomGap: "40px"
};

export const OptionPanelType = {
  MENU: 0,
  LANGUAGE: 1,
  CATEGORY: 2
};

export const noop = () => {};
