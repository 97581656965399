import { OptionPanelType, SCREEN_SWITCH_DURATION, d, noop } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx } from "@emotion/core";
import { selectCategories, selectLanguages } from "../../services";

import CategorySelect from "./CategorySelect";
import { GlanceLogo } from "./svg-icons";
import LanguageSelect from "./LanguageSelect";
import React from "react";
import colors from "../../common/colors";
import { fireEvent } from "../../utils/tracking";
import i18n from "../../../../i18n";
import styled from "@emotion/styled";

// Panel
const trans = {};
trans[OptionPanelType.MENU] = css`
  bottom: -100%;
`;
trans[OptionPanelType.LANGUAGE] = css`
  bottom: 0;
`;
trans[OptionPanelType.CATEGORY] = css`
  bottom: 0;
`;

const Holder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 6;
  top: 100%;
  overflow: hidden;
  ${p => trans[p.type]}
  ${p =>
    p.type === OptionPanelType.MENU
      ? css`
          top: 0;
        `
      : p.type === OptionPanelType.LANGUAGE
      ? css`
          top: 0;
        `
      : p.type === OptionPanelType.CATEGORY
      ? css`
          top: 0;
        `
      : ""}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  background-image: linear-gradient(to bottom, #0d0d0d 23%, rgba(0, 0, 0, 0));
  background-repeat: no-repeat;
`;

const Menu = styled.div`
  position: absolute;
  z-index: 3;
  top: calc(${d.glanceTopGap} + 13px);
  right: calc(${d.glanceSideGap} + 13px);
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-image: linear-gradient(
    to top,
    rgba(249, 17, 81, 0.9),
    rgba(249, 17, 81, 0.3)
  );
`;
const MenuItem = styled.button`
  font-size: 14px;
  font-weight: 800;
  line-height: 1.57;
  text-align: left;
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 10px 20px 10px 10px;
  color: #ffffff;

  display: flex;
  align-items: center;
  &:before {
    content: "";
    display: inline-block;
    background-image: url("${p => p.icon}");
    background-size: 100% 100%;
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }
`;

const CloseButton = styled.button`
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #353535;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/static/images/buzzstorm/icon-plus.svg");
  transform: rotate(45deg);
  border: 0;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  position: absolute;
  right: -17px;
  top: -17px;
`;

const CircleBg = styled.div`
  background-image: url("/static/images/buzzstorm/bg-circle.svg");
  background-size: 100%;
  position: absolute;
  width: 100vh;
  height: 100vh;
  z-index: 1;
  top: calc(100vh - 327px);
  left: 50%;
  z-index: 3;
  transition: transform ${SCREEN_SWITCH_DURATION}ms ease-in-out;
  transform: translateX(-50%);
  transition-property: transform, width, height;
  transform-origin: center;

  &:before,
  &:after {
    content: "";
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(
      to bottom,
      ${colors.redRibbon},
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
  &:after {
    background-image: linear-gradient(
      to bottom,
      rgba(249, 17, 81, 0.3),
      ${colors.redRibbon} 57%
    );
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: ${parseInt(d.glanceSideGap) * 2}px;
  right: ${parseInt(d.glanceSideGap) * 2}px;
  bottom: ${parseInt(d.glanceSideGap) * 3}px;
`;
const Title = styled.h1`
  font-size: 32px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -1.28px;
  text-align: left;
  color: ${colors.white};
  margin: 0;
`;
const NextButton = styled.button`
  border: 0;
  outline: 0;
  color: #f4f4f4;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.42px;
  color: #f4f4f4;
  white-space: nowrap;
  height: 36px;
  padding-right: 47px;
  position: relative;
  background-color: transparent;
  &:after {
    content: "";
    position: absolute;
    width: 36px;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("/static/images/buzzstorm/icon-arrow-right-white.svg");
    background-size: 100%;
    transition: 1s background;
  }
  &.inverse {
    color: ${colors.mineShaft};
    &:after {
      background-image: url("/static/images/buzzstorm/icon-arrow-right-color.svg");
    }
  }
`;

const moveLogoLeft = css`
  fill: ${colors.redRibbon};
  width: 70px;
  height: 25px;
  top: ${d.glanceTopGap};
  left: ${d.glanceSideGap};
  transform: scale(1) translate(0, 0);
`;

const Logo = styled(GlanceLogo)`
  position: absolute;
  z-index: 10;
  ${moveLogoLeft}
`;
const OptionPanel = props => {
  const {
    t,
    type,
    setOptionPanelType,
    isUpdating = true,
    onPanelOpen = noop,
    onPanelClose = noop
  } = props;

  const [selectedLanguages, setSelectedLanguages] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const onClose = React.useCallback(() => {
    setOptionPanelType(null);
    onPanelClose();
  }, [setOptionPanelType, onPanelClose]);
  const onDone = React.useCallback(async () => {
    if (type === OptionPanelType.LANGUAGE && selectedLanguages.length > 0) {
      await selectLanguages(selectedLanguages);
      const currentScreen = isUpdating ? "UPDATING" : "ONBOARDING";
      fireEvent(
        "Language Select",
        "Click - " + currentScreen,
        selectedLanguages.join()
      );
    } else if (
      type === OptionPanelType.CATEGORY &&
      selectedCategories.length > 0
    ) {
      await selectCategories(selectedCategories);
      const currentScreen = isUpdating ? "UPDATING" : "ONBOARDING";
      fireEvent(
        "Category Select",
        "Click - " + currentScreen,
        selectedCategories.join()
      );
    }
    onPanelClose();
    setOptionPanelType(null);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }, [
    type,
    selectedLanguages,
    selectedCategories,
    setOptionPanelType,
    isUpdating,
    onPanelClose
  ]);

  if (type === null) return null;
  return (
    <Holder data-testid="option-panel" {...props} css={css``}>
      <Logo />
      <Overlay type={type} />
      {type === OptionPanelType.MENU && (
        <Menu>
          <CloseButton onClick={onClose} />
          <MenuItem
            icon={`/static/images/buzzstorm/icon-categories.svg`}
            onClick={() => setOptionPanelType(OptionPanelType.CATEGORY)}
          >
            {t("Categories")}
          </MenuItem>
          <MenuItem
            icon={`/static/images/buzzstorm/icon-languages.svg`}
            onClick={() => setOptionPanelType(OptionPanelType.LANGUAGE)}
          >
            {t("Languages")}
          </MenuItem>
        </Menu>
      )}
      {type === OptionPanelType.LANGUAGE && (
        <div>
          <LanguageSelect
            type={"invert"}
            onLanguageSelect={setSelectedLanguages}
          />
          <Footer>
            <Title>
              {t("Choose Your")}
              <br />
              {t("Languages")}
            </Title>
            <div>
              <NextButton onClick={onDone}>{t("Done")}</NextButton>
            </div>
          </Footer>

          <CircleBg type={type} />
        </div>
      )}
      {type === OptionPanelType.CATEGORY && (
        <div>
          <CategorySelect
            type={"invert"}
            onCategorySelect={setSelectedCategories}
          />
          <Footer>
            <Title>
              {t("Choose Your")}
              <br />
              {t("Interests")}
            </Title>
            <div>
              <NextButton onClick={onDone}>{t("Done")}</NextButton>
            </div>
          </Footer>

          <CircleBg type={type} />
        </div>
      )}
    </Holder>
  );
};

export default i18n.withTranslation("common")(OptionPanel);
