import React from "react";
import css from "./empty-card.module.scss";
import { fireEvent } from "../utils/tracking";

function fireSelectCategoriesEvent() {
  fireEvent(
    "Glance",
    "Empty Glance Select Categories Button Click",
    "Empty Glance Screen Select Categories Button Click"
  );
}

export default function EmptyGlanceCard(props) {
  const { isSourceApiIntegration = false } = props;
  return (
    <div data-testid="empty-glance" className={css["error-page"]}>
      <div className={css["inner-cont"]}>
        <img
          src={"/static/images/error-img.png"}
          alt="Glance Waiting"
          className={css["error-img"]}
        />
        <div className={css["error-heading"]}>Uh oh!</div>
        <div className={css["error-subheading"]}>
          We didn't find any matching glances for you.{" "}
          {!isSourceApiIntegration ? (
            <>Please select some more categories of your interest.</>
          ) : null}
        </div>
        {!isSourceApiIntegration ? (
          <a
            href="/home"
            className={css["back-btn"]}
            onClick={fireSelectCategoriesEvent}
          >
            Select Categories
          </a>
        ) : null}
      </div>
    </div>
  );
}
